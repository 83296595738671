import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Pierre Gilbert",
      position: "CEO, On-Site Languages",
      src: "images/testimonial/client-sm-1.jpg",
      desc: "“Solomon created my website and did the design on my meetup groups.He did an excellent job. My website looks professional and people are interested to visit it. Solomon is the most creative and capable web designer we had. He functions at a high level with all of the standard graphics software currently being used. I strongly recommend Solomon for your website.”",
    },
    {
      name: "Christina Wang",
      position: "Website Designer, NextHome (formerly Wall2Wall Media)",
      src: "images/testimonial/client-sm-2.jpg",
      desc: " “Recently, when Wall2Wall Media migrated its Quebec clients to its new Real Estate CMS platform, I witnessed Solomon's enthusiasm and dedication to his job. He was eager to show his digital skillset in HTML. Solomon was a great help, especially with his ability to speak French, as he learned the platform quickly and understood which elements needed to be brought over. I was able to assign him several client accounts and received them completed in a timely manner with minimal supervision. Overall, Solomon demonstrated a great work ethic during this project and I enjoyed working with him greatly.”",
    },
    {
      name: "Venkata Karamchedu",
      position: "Consultant, Aardith Global Group",
      src: "images/testimonial/client-sm-3.jpg",
      desc: "“It’s been a pleasure working with Solomon. He has primarily helped me with various sell sheets for Aardith Global Group Inc. in both English and French. He also has helped with specialty products such as banner ads for our website and the creation of a logo. Solomon has always completed all projects on time and with creativity and quality of design. He has particulary shown his excellent skills in InDesign, Illustrator and Photoshop. I would highly recommend him.”",
    },

  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-secondary">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-2">Client Speak</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          Testimonials
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp">
            <Slider {...settings}>
              {reviews.length > 0 &&
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <img
                      className="img-fluid d-inline-block w-auto rounded-circle shadow"
                      src={review.src}
                      alt={review.name}
                    />{" "}
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                    <span className="text-light">{review.position}</span>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
