import React from "react";
import resumeFile from "../documents/solomon-obadia-resume.docx";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2022",
      title: "JavaScript and ReactJS",
      place: "Seneca College",
      desc: "This course provided me with a solid foundation in Javascript, ES6 and the React MVVN framework. Topics included Javascript/ES6 essentials, Command Line Usage, local development in ReactJS and Integration with Redux.",
    },
    {
      // yearRange: "2015 - 2017",
      title: "Web & Mobile Development Diploma",
      place: "Trios College",
      desc: "I learned to develop applications that run on mobile platforms such as Google Android, Apple iOS, and Windows Phone. This Enterprise Web and Mobile Developer program came with a 16-week internship to give me real-world, hands-on experience and made myself job-ready by the time you graduated.",
    },
    {
      // yearRange: "1987 - 1991",
      title: "Graphic Design Diploma",
      place: "George Brown College",
      desc: "The Graphic Design program was an ideal training ground for the design profession. As a student in the program, I gained a strong overall understanding of design which included primarily corporate design, advertising design, typography, life drawing and illustration",
    },
  ];

  const experienceDetails = [
    {
      // yearRange: "2018 - 2021",
      title: "Web Designer / Developer",
      place: "Identity Namebrands",
      desc: "Developed WordPress websites for primarily dental clients using WordPress, CSS, HTML, JavaScript, PHP and various WordPress plugins.",
    },
    {
      // yearRange: "2018 - 2020",
      title: "Web Designer",
      place: "BreezeMaxWeb",
      desc: "Produced WordPress website sites for clients of various professions primarily using Wordpress, CSS, HTML and JavaScript ",
    },
    {
      // yearRange: "2016 - 2017",
      title: "Computer Technician",
      place: "Ripcord Technologies",
      desc: "Primarily focused on Microsoft server and cloud-based applications including Office365, and Dynamics CRM, as well as general web development using server-side ASP.NET (MVC). Microsoft Excel and of course the entire modern-HTML client-side stack of technologies. ",
    },
    {
      // yearRange: "2012 - 2014",
      title: "Bilingual Layout Artist",
      place: "NextHome (Formerly Wall2Wall Media)",
      desc: "Designed layout ads in monthly real estate publications for the Québec, Ontario and the West regions using Adobe InDesign, Adobe Illustrator and Adobe Photoshop.",
    },
    {
      // yearRange: "1999 - 2009",
      title: "Layout Artist",
      place: "Yellow Pages Group",
      desc: "Created 1/4 to full-page black/white and full colour process ads in a range of professions using primarily Illustrator and Photoshop.",
    },
  ];

  // const skills = [
  //   {
  //     name: "Adobe Creative Suite",
  //     percent: 100,
  //   },

  //   {
  //     name: "Web Design",
  //     percent: 100,
  //   },
  //   {
  //     name: "HTML/CSS",
  //     percent: 95,
  //   },
  //   {
  //     name: "JavaScript",
  //     percent: 80,
  //   },
  //   {
  //     name: "React JS",
  //     percent: 70,
  //   },
  //   {
  //     name: "Tailwind CSS",
  //     percent: 60,
  //   },
  //   {
  //     name: "Material UI",
  //     percent: 50,
  //   },
  // ];

  return (
     <section id="resume" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
       {/* <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>*/}
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
